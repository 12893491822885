// main.js
import { createApp } from 'vue';
import App from './App.vue';
import naive from 'naive-ui'; // Importa o Naive UI

const app = createApp(App);

// Usa o Naive UI em toda a aplicação
app.use(naive);

app.mount('#app');
