<template>
  <div>
    <div v-if="!videoEnded">
      <video ref="quizVideo" @ended="handleVideoEnded" controls>
        <source
          src="https://res.cloudinary.com/prime-arte/video/upload/v1710866539/flashvolve/miq5odndimnurotm3ivf.mp4"
          type="video/mp4"
        />
        Seu navegador não suporta o elemento de vídeo.
      </video>
    </div>
    <div v-if="videoEnded">
      <h2>Quiz</h2>
      <div v-for="(question, index) in questions" :key="index" class="question">
        <p>{{ question.text }}</p>
        <div v-for="(option, idx) in question.options" :key="idx">
          <n-radio-group v-model:value="answers[index]" name="options">
            <n-radio :value="option">{{ option }}</n-radio>
          </n-radio-group>
        </div>
      </div>
      <n-button @click="submitQuiz">Submit</n-button>
    </div>
  </div>
</template>

<script setup>
import { ref, reactive, onMounted, onUnmounted } from 'vue';
import { NRadio, NRadioGroup, NButton } from 'naive-ui';
import * as SCORM from 'pipwerks-scorm-api-wrapper';

const quizVideo = ref(null);
const videoEnded = ref(false);

const handleVideoEnded = () => {
  videoEnded.value = true;
};

const questions = reactive([
  {
    text: 'Qual é a capital da França?',
    options: ['Paris', 'Londres', 'Berlim', 'Madri'],
  },
  {
    text: 'Qual é o maior oceano do mundo?',
    options: ['Atlântico', 'Pacífico', 'Índico', 'Ártico'],
  },
]);

const answers = reactive({});

const submitQuiz = () => {
  console.log('Respostas:', answers);
  alert('Quiz submetido! Verifique o console para as respostas.');
  const responses = JSON.stringify(answers);
  SCORM.set("cmi.suspend_data", responses);
  SCORM.save();
};

const startSession = () => {
  SCORM.init();
  console.log('Sessão SCORM iniciada');
};

const endSession = () => {
  SCORM.save();
  SCORM.quit();
  console.log('Sessão SCORM finalizada');
};

onMounted(() => {
  startSession();
});

onUnmounted(() => {
  endSession();
});
</script>

<style scoped>
video {
  width: 100%;
  height: auto;
}

.question {
  margin-bottom: 20px;
}
</style>
